export function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

/* tslint:disable:prefer-for-of */
export function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
/* tslint:enable:prefer-for-of */

export function createCookie(name: string, value: string, days?: number) {

  const date = new Date()
  date.setTime(date.getTime() + ((days || 365) * 24 * 60 * 60 * 1000))
  const expires = '; expires=' + date.toUTCString()

  document.cookie = name + '=' + value + expires + '; path=/'
}

export function eraseCookie(name: string) {
  createCookie(name, '', -1)
}

declare global {
  
  interface Window {
    eraseCookie: typeof eraseCookie
  }
}
if (typeof window != 'undefined') {
  window.eraseCookie = eraseCookie
}
