import * as $ from 'jquery'
import { getCookie, setCookie } from '../lib/cookies'

export default function() {
  const $section = $('.unaffiliated-user-warning')

  if (getCookie('unaffiliated-user-warning-hidden')) {
    $section.hide()
  } else if (getCookie('unaffiliated-user-warning')) {
    $section.show()
  }

  $('.js-unaffiliated-user-warning-hide').on('click', (e) => {
    setCookie('unaffiliated-user-warning-hidden', true, 1)
    $section.hide()
  })

  $('.js-unaffiliated-user-warning-contact').on('click', (e) => {
    setCookie('unaffiliated-user-warning-hidden', true, 1)
    $section.hide()
  })
}
