import * as $ from 'jquery'
import * as UAParser from 'ua-parser-js'

import { getCookie, setCookie } from '../lib/cookies'

export default function() {
  const $section = $('.outdated-browser-warning')

  const tests: { [key: string]: boolean } = {
    es6object: false,
    urlsearchparams: false,
  }

  if (getCookie('browser-warning-hidden')) {
    $section.hide()
  } else {
    Object.keys(tests).forEach((test) => {
      window.Modernizr.on(test, (result) => {
        tests[test] = result

        if (Object.keys(tests).every((t) => tests[t] == true)) {
          // all tests succeeded
          $section.hide()
        } else if (!result) {
          // some test failed
          $section.addClass('ga-browser-warning-shown')
        }
      })
    })
  }

  $('.js-browser-warning-hide').on('click', (e) => {
    setCookie('browser-warning-hidden', true, 1)
    $section.hide()
  })

  $('.js-browser-warning-update').on('click', (e) => {
    const ua = new UAParser()
    const query = {
      q: '!ducky ' + suggestedBrowser(ua.getBrowser().name),
    }

    window.open('https://duckduckgo.com/?' + $.param(query) , '_blank')
  })

  function suggestedBrowser(name) {
    const validBrowsers = /Safari|Chrome|Firefox|Edge/
    const recommendations = { IE: 'Microsoft Edge' }

    if (validBrowsers.test(name) && name) { return name }

    return recommendations[name] || 'Google Chrome'
  }
}
